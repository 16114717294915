<template>
    <div>
        <div v-if="show" class="customLoader mr-10 d-flex justify-content-center align-items-center">
            <div class="">
                <div>
                    <b-img style="height: 120px; width: 120px;" src="@/assets/images/logo/Logo.svg" />
                </div>

                <div class="d-flex justify-content-center mt-2">
                    <b-spinner style="height: 40px; width: 40px;"></b-spinner>
                </div>
            </div>

        </div>

        <slot>

        </slot>


    </div>
</template>

<script>
    import {BImg, BSpinner} from 'bootstrap-vue'

    export default {
        components: {
            BImg,
            BSpinner
        },
        props: {
            show: {
                default: true
            }
        }
    }
</script>

<style scoped>
  .customLoader{
    top:0;
    left: 0;
    position: absolute;
    min-height: 450px;
    height: calc(100% - 105px);
    width: 100%;
    border-radius: 5px;
    z-index: 1200;
  }

  .dark-layout .customLoader{
    background: #3e3e3e;
  }

  .light-layout .customLoader{
    background: white;
  }
</style>