<template>
    <div class="relative">
        <custom-loader :show="showLoader">
            <div class="py-1 d-flex justify-content-between" v-if="showTableOptions">
                <div>
                    <b-button-group style="height: 38px">
                        <b-button @click="reloadData" class="px-1 p-0" variant="primary" style="border-bottom-left-radius: 20px; border-top-left-radius: 20px;">
                            <feather-icon class="large-icon" icon="RefreshCwIcon"/>
                        </b-button>
                        <b-button @click="clearFilters" class="px-1 p-0" variant="primary" style="border-bottom-right-radius: 20px; border-top-right-radius: 20px;">
                            <img style="height: 25px; margin:0;" src="@/assets/images/icons/clearFilter.svg" alt="Refresh icon" />
                        </b-button>
                    </b-button-group></div>
                <div><slot name="buttons"></slot></div>
            </div>

            <b-table :id="getTableName()"
                     class="customTable text-center"
                     thead-class="header-top"
                     sticky-header show-empty
                     select-mode="single"
                     style="user-select: none; max-height: 100% !important ; height: 100%;"
                     :filter="tableFilters"
                     :filter-function="filterTable"
                     :ref="'customTable_' + getTableName()"
                     :per-page="tablePerPage"
                     :current-page="tableCurrentPage"
                     :items="showItems"
                     :fields="computedFields"
                     :tbody-tr-class="rowClass"
                     @filtered="onFiltered">

                <template #head()="scope">
                    <div style="getMinWidth(scope.field.minWidth, scope.field.maxWidth)" class="d-flex justify-content-center w-100 ">
                        <div>
                            <div class="textTableHeader" :style="getMinWidth(scope.field.minWidth, scope.field.maxWidth)" style="text-transform: none;margin-bottom: 10px; font-size: 13px; height: 25px;" :class="!scope.field.filter || !(scope.field.permissions || scope.field.permissions === '') ? 'align-top' : ''">
                                {{ scope.field.label }}
                            </div>

                            <div class="filter-col" v-if="scope.field.filter && (scope.field.permissions || scope.field.permissions === '')">
                                <div v-if="scope.field.filterType === 'input'">
                                    <b-form-input style="height: 30px;" debounce="300" class="customInputFieldTable"  @update="callLoadData" @focus="focus=true" v-model="filters[scope.column]"/>
                                </div>

                                <div v-else-if="scope.field.filterType === 'date'">
                                    <date-picker :locale="currentLocale" :first-day-of-week="2" :model-config="modelConfig" :popover="popoverOptions" @popoverDidHide="onDatePickerClose"  @input="onDateInput" popover-direction="top" class="relative w-100" v-model="filters[scope.column]" is-range :masks="{input: ['DD. MM. YYYY'], data: ['DD. MM. YYYY']}">
                                        <template #default="{ inputValue, inputEvents }">
                                            <div class="flex justify-center items-center">
                                                <b-input-group>
                                                    <b-form-input class="not-disabled customDatePickerTable customDatePicker" :value="inputValue.start !== null ? `${inputValue.start} - ${inputValue.end}` : ''" v-on="inputEvents.start" @click="callLoadData" disabled />
                                                </b-input-group>
                                            </div>
                                        </template>
                                    </date-picker>
                                </div>

                                <div v-else-if="scope.field.filterType === 'select'" >
                                    <v-select v-model="filters[scope.column]" @input="callLoadData" appendToBody class="floating-filter w-100" :options="scope.field.filterParams" :searchable=true :label="scope.field.filterParamsName" :reduce="scope.field.reduce">
                                        <template #selected-option="{name}">
                                            <span  class="textCutSelect">
                                                {{ name }}
                                            </span>
                                        </template>
                                    </v-select>
                                </div>
                                <div v-else-if="scope.field.filterType === 'checkbox' && apiUrl === ''" class="d-flex justify-content-center align-items-center">
                                    <b-form-checkbox v-model="selectAll" variant="primary" :indeterminate="indeterminate" @change="selectAllRows"/>
                                </div>
                            </div>
                        </div>
                    </div>

                </template>

                <template #empty>
                    <slot name="empty">
                        <div>
                            {{ $t('table_fields.no_data') }}.
                        </div>
                    </slot>
                </template>

                <template #cell()="item">
                    <div role="item" :aria-rowindex="item.index" class="d-flex justify-content-center column" style="font-size: 13px;" :id="item.field.key">
                        <slot :name="item.field.key" v-bind="item">
                            <div v-if="showEditing !== item.index + '-' + item.field.key || !item.field.editable" :style="getMinWidth(item.field.minWidth, item.field.maxWidth)">
                                <div v-if="item.field.formatter" class="textCut" >
                                    {{item.field.formatter(item.item[item.field.key])}}
                                </div>
                                <div v-else-if="item.field.formatterItem" class="textCut">
                                    {{item.field.formatterItem(item.item)}}
                                </div>
                                <div v-else-if="item.field.key === 'checkboxes'">
                                    <div v-if="selectedRows.indexOf(item.item[selectionId]) > -1" :class="themeSkin === 'dark' ? 'invert' : ''">
                                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16" height="16" viewBox="0 0 24 24">
                                            <path d="M5.268,10.732c-0.976-0.976-2.559-0.976-3.536,0s-0.976,2.559,0,3.536l4.645,4.645c1.449,1.449,3.797,1.449,5.246,0L12.536,18L5.268,10.732z" opacity=".35"></path><path d="M22.268,4.732c-0.976-0.976-2.559-0.976-3.536,0L9,14.464L12.536,18l9.732-9.732C23.244,7.291,23.244,5.708,22.268,4.732z"></path>
                                        </svg>
                                    </div>
                                </div>
                                <span class="textCut" v-else>
                                    {{getItem(item.item, item.field.key)}}
                                </span>
                            </div>
                            <div style="width: 90%; min-width: 50px; height: 25px;" v-else>
                                <b-form-input :ref="'input_field_editing_' +showEditing" @blur="inputChanged(item)" v-model="enteredText" v-if='!item.field.editableType || item.field.editableType === "input"' style="height: 25px"></b-form-input>
                                <v-select v-model="selectedItem" @input="selectChanged(item)" appendToBody style="width: 100%; height: 25px; top: -3px;" v-if='item.field.editableType === "select"' :label="item.field.filterParamsName" :options="item.field.filterParams"></v-select>
                            </div>
                        </slot>
                    </div>
                </template>
            </b-table>


            <b-row v-if="pagination.total_items > pagination.per_page">
                <b-col cols="12" md="6" >
                    <div class="d-flex d-md-block justify-content-center">
                        <div v-if="showItems.length > 0 && pagination" class="d-flex align-items-center">
                            <div>
                                {{ ((pagination.current_page -1) * pagination.per_page) + 1 }} -
                                <span v-if="pagination.current_page * pagination.per_page < pagination.total_items">
                                    {{ pagination.current_page * pagination.per_page}}
                                </span>
                                <span v-else>
                                    {{pagination.total_items}}
                                </span>
                                {{ $t('pagination.of') }} {{ pagination.total_items }}
                            </div>
                            <div >
                                <v-select class="ml-1 pageSelect" :options="pageLengths" v-model="pagination.per_page" :clearable="false" appendToBody @input="changePerPage" style="width: 140px;">
                                    <template #selected-option="{label}">
                                        <span style="font-size: 12px; margin-bottom: 10px;" class="d-flex align-items-center">
                                            <span>
                                                {{ label }} {{$t('pagination.per_page')}}
                                            </span>
                                        </span>
                                    </template>
                                </v-select>
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col cols="12" md="6" class="d-flex justify-content-center justify-content-md-end">
                    <b-pagination v-model="pagination.current_page"
                                  @input="callLoadData"
                                  last-number first-number pills
                                  :total-rows="pagination.total_items"
                                  :per-page="pagination.per_page"
                                  size="md"
                                  align="center"
                                  :aria-controls="getTableName()"
                                  :disabled="loadingActive">
                    </b-pagination>
                </b-col>
            </b-row>
        </custom-loader>
    </div>
</template>
<script>

    import {
        BButton,
        BButtonGroup,
        BCol,
        BFormCheckbox,
        BFormInput,
        BInputGroup,
        BPagination,
        BRow,
        BTable
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import 'vue-select/dist/vue-select.css'
    import * as Sentry from '@sentry/vue'
    import {DatePicker} from 'v-calendar'
    import Fuse from 'fuse.js'
    import CustomLoader from '@/components/CustomLoader.vue'

    export default {
        components: {
            CustomLoader,
            BButton,
            BFormCheckbox,
            BRow,
            BCol,
            BButtonGroup,
            BTable,
            BInputGroup,
            BFormInput,
            BPagination,
            vSelect,
            DatePicker
        },
        props: {
            showTableOptions: {
                type: Boolean,
                default: true
            },
            fields: {
                type: Array
            },
            apiUrl: {
                type: String,
                default: ''
            },
            name: {
                type: String
            },
            additionalFilters: {
                type: Object
            },
            items: {
                type: Array
            },
            checkboxes: { // checkboxes alongside every row
                type: Boolean,
                default: false
            },
            selected: { // items that should be selected on load
                type: Array
            },
            selectionId: { // which atribute do you want to use for checkbox id, default is 'id'
                type: String,
                default: 'id'
            }
        },
        data() {
            return {
                showLoader: true,
                pageLengths: [10, 20, 50, 100],
                modelConfig: {
                    type: 'string',
                    mask: 'iso'
                },
                popoverOptions: {
                    positionFixed: true
                },
                timestamp: 'bill_code',
                pagination: {
                    per_page: 10,
                    current_page: 1,
                    total_pages: 0,
                    total_items: 0
                },
                filters: {},
                apiItems: [],
                loadingActive: false,
                lastClickOnTable: new Date(),
                lastClickCheck: new Date(),
                showEditing: '',
                selectedRow: -1,
                selectedCol: -1,
                enteredText: '',
                selectedItem: null,
                selectAll: false,
                selectedRows: [],
                indeterminate: false,
                dateInput: false
            }
        },
        computed: {
            showItems() {
                if (this.apiUrl !== '') {
                    return this.apiItems
                } else {
                    return this.items
                }
            },
            currentLocale() {
                return this.$store.getters['user/current_locale']
            },
            themeSkin() {
                return this.$store.getters['appConfig/currentSkin']
            },
            computedFields() {
                if (this.checkboxes && this.apiUrl === '') { // checkboxes delujejo samo s statičnimi itemi
                    const newFields = this.deepClone(this.fields)
                    newFields.unshift({
                        key: 'checkboxes',
                        label: '',
                        filter: true,
                        filterType: 'checkbox',
                        permissions: true,
                        maxWidth: 40,
                        minWidth: 40
                    })
                    return newFields
                } else {
                    return this.fields
                }
            },
            tableFilters() {
                if (this.apiUrl === '') {
                    return this.filters
                } else {
                    return null
                }
            },
            tablePerPage() {
                if (this.apiUrl !== '') {
                    return 0
                } else {
                    return this.pagination.per_page
                }
            },
            tableCurrentPage() {
                if (this.apiUrl !== '') {
                    return 1
                } else {
                    return this.pagination.current_page
                }
            }
        },
        methods: {
            selectedRowsLength() { // need to know how many selected rows we have when filtering for indeterminate
                if (!this.isFiltersEmpty()) { // filtering
                    const items = this.manualFiltering()

                    let counter = 0
                    for (const i in items) { // get number of selected items in filtered selection
                        if (items && items[i] && items[i][this.selectionId]) {
                            const itemId = items[i][this.selectionId]

                            const idx = this.selectedRows.indexOf(itemId)
                            if (idx > -1) {
                                counter += 1
                            }
                        }
                    }

                    return counter
                } else { // not filtering
                    return this.selectedRows.length
                }
            },
            rowClass(item, type) {
                if (item && type === 'row') {
                    if (this.selectedRows.indexOf(item[this.selectionId]) !== -1 && this.checkboxes) {
                        return this.themeSkin === 'dark' ? 'table-dark' : 'table-light'
                    }
                }
                return null
            },
            indeterminateAndSelectAllSet() {
                if (this.selectedRowsLength() === 0) {
                    this.indeterminate = false
                    this.selectAll = false
                } else if (this.selectedRowsLength() < this.pagination.total_items) {
                    this.indeterminate = true
                } else if (this.selectedRowsLength() === this.pagination.total_items) {
                    this.selectAll = true
                    this.indeterminate = false
                }
            },
            isFiltersEmpty() {
                if (Object.keys(this.filters).length === 0 && this.filters.constructor === Object) {
                    return true
                }

                for (const key of Object.keys(this.filters)) {
                    if (this.filters[key]) {
                        return false
                    }
                }

                return true
            },
            manualFiltering() {
                const items = []

                for (const i in this.showItems) {
                    if (this.showItems && this.showItems[i]) {
                        if (this.filterTable(this.showItems[i], this.filters)) {
                            items.push(this.showItems[i])
                        }
                    }
                }

                return items
            },
            async selectAllRows() {
                if (this.selectedRowsLength() < this.pagination.total_items) { // niso izbrani vsi itemi, zato bomo izbrali vse
                    let items = []

                    if (!this.isFiltersEmpty()) {
                        items = this.manualFiltering()
                    } else {
                        items = this.showItems
                    }

                    for (let i = 0; i < items.length; i++) {
                        if (items[i] && items[i][this.selectionId]) {
                            if (!this.selectedRows.includes(items[i][this.selectionId])) {
                                this.selectedRows.push(items[i][this.selectionId])
                            }
                        }
                    }
                } else if (!this.isFiltersEmpty()) { // uporablja se filtriranje, ob kliku na gumb odstranimo zgolj trenutno prikazane
                    const items = this.manualFiltering()

                    for (const i in items) {
                        if (items && items[i] && items[i][this.selectionId]) {
                            const itemId = items[i][this.selectionId]

                            const idx = this.selectedRows.indexOf(itemId)
                            if (idx > -1) {
                                this.selectedRows.splice(idx, 1)
                            }
                        }
                    }
                } else if (this.selectedRows.length >= this.pagination.total_items) { // izbrani so vsi itemi in odstranimo vse
                    this.selectedRows = []
                }

                this.indeterminateAndSelectAllSet()
            },
            deepClone(fields) {
                const deepCopy = []
                for (const field of fields) {
                    const deepCopyItem = {}
                    for (const key of Object.keys(field)) {
                        if (typeof field[key] === 'function') {
                            deepCopyItem[key] = field[key].bind({})
                        } else  {
                            deepCopyItem[key] = JSON.parse(JSON.stringify(field[key]))
                        }
                    }
                    deepCopy.push(deepCopyItem)
                }

                return deepCopy
            },
            onFiltered(filteredItems) {
                this.pagination.total_items = filteredItems.length
                this.pagination.current_page = 1
            },
            filterTable(row, filter) {
                for (const field of this.fields) {

                    let filterKey = field.key

                    if (field && field.filterKey) {
                        filterKey = field.filterKey
                    }

                    if (!filter[field.key]) {
                        continue
                    }

                    if (field && field.filterFunction) {
                        if (!field.filterFunction(row, filter[field.key])) {
                            return false
                        }
                        continue
                    }

                    if (field && field.filterType === 'select') {
                        if (this.getItem(row, filterKey) !== filter[field.key]) {
                            return  false
                        }
                    } else if (field && field.filterType === 'input') {
                        const key = filterKey.endsWith('.w') ? filterKey.substring(0, filterKey.length - 2) : filterKey
                        let tmpCol = row[key]

                        if (field.formatter) {
                            tmpCol = field.formatter(tmpCol)
                        }

                        const threshold = filterKey.endsWith('.w') ? 0.3 : 0
                        const minCharLength = filterKey.endsWith('.w') ? 2 : 1
                        if (!this.fuseSearch([tmpCol], filter[field.key], [key], { threshold, minCharLength })) {
                            return false
                        }

                    } else if (field && field.filterType === 'date') {
                        const start = this.dayjs(filter[field.key].start).hour(0).minute(0).second(0)
                        const end = this.dayjs(filter[field.key].end).hour(23).minute(59).second(59)
                        const current = this.dayjs(row[filterKey])

                        if (!((current.isBefore(end)  && current.isAfter(start)) || (current.isSame(start, 'minutes') || current.isSame(end)))) {
                            return false
                        }
                    }
                }

                return true
            },
            fuseSearch(list, search, keys, options = {}) {
                if (!search || !search.length) {
                    return false
                }

                const fuse = new Fuse(list, {
                    keys,
                    ...options
                })

                return fuse.search(search).length > 0
            },
            onDateInput() {
                this.dateInput = true
            },
            async onDatePickerClose() {
                if (!this.dateInput) {
                    return
                }

                await this.callLoadData()

                this.dateInput = false
            },
            async callLoadData() {
                if (this.loadingActive) {
                    return
                }

                this.setTableFiltersToURL()

                await this.loadData()
                this.indeterminateAndSelectAllSet()
            },
            async reloadData() {
                if (this.pagination) {
                    this.pagination.current_page = 1
                }
                await this.loadData()
                this.$emit('reloadData')
            },
            async clearFilters() {
                this.filters = {}
                await this.loadData()
            },
            async changePerPage() {
                this.pagination.current_page = 1
                await this.loadData()
            },
            inputChanged(item) { //Editanje preko inputa
                if (!this.enteredText) {
                    return
                }

                const id = item.field.editReturnParam ?? 'id'
                let returnItem = ''

                if (this.selectedRow >= 0 && this.selectedRow < this.apiItems.length) {
                    returnItem = this.apiItems[this.selectedRow][id]
                } else if (this.selectedRow >= 0 && this.selectedRow < this.items.length) {
                    returnItem = this.items[this.selectedRow][id]
                }

                let col_id = ''

                if (this.selectedCol >= 0 && this.selectedCol < this.fields.length) {
                    const colKey = this.fields[this.selectedCol].key

                    if (colKey) {
                        col_id = colKey
                    }
                }

                const payload = {
                    value: this.enteredText,
                    item_id: returnItem,
                    col_id,
                    rowIndex: this.selectedRow,
                    colIndex: this.selectedCol
                }

                this.$emit('edit', payload)

                this.showEditing = ''
                this.selectedRow = -1
                this.selectedCol =  -1
                this.enteredText = ''
                this.selectedItem = null
            },
            selectChanged(item) { //Editanje z v selectom
                const id = item.field.editReturnParam ?? 'id'
                let returnItem = ''

                if (this.selectedRow >= 0 && this.selectedRow < this.apiItems.length) {
                    returnItem = this.apiItems[this.selectedRow][id]
                }

                let item_id = ''

                if (this.selectedItem) {
                    item_id = this.selectedItem[id]
                }


                let col_id = ''

                if (this.selectedCol >= 0 && this.selectedCol < this.fields.length) {
                    const colKey = this.fields[this.selectedCol].key

                    if (colKey) {
                        col_id = colKey
                    }
                }


                const payload = {
                    value: item_id,
                    item_id: returnItem,
                    col_id,
                    rowIndex: this.selectedRow,
                    colIndex: this.selectedCol
                }

                this.$emit('edit', payload)

                this.showEditing = ''
                this.selectedRow = -1
                this.selectedCol =  -1
                this.enteredText = ''
                this.selectedItem = null
            },
            getMinWidth(minWidth, maxWidth) {
                let text = 'text-overflow: ellipsis; white-space: nowrap;  overflow: hidden; text-align: center; width: 100% !important; '

                if (minWidth) {
                    text += `min-width: ${  minWidth  }px;`
                } else {
                    text += 'min-width: 100px;'
                }

                if (maxWidth) {
                    text += `max-width: ${  maxWidth  }px !important;`
                }


                return text
            },
            getItem(item, key) {
                const value = key.split('.').reduce((p, c) => (p && p[c]) || null, item)

                if (!value) {
                    return '/'
                }

                return  value
            },
            getFilterFormatter(key) {
                for (const item of this.fields) {
                    if (item.key === key) {
                        if (item.filterFormatter) {
                            return item.filterFormatter
                        }
                        break
                    }
                }

                return null
            },
            getFilters() {
                let filter = ''

                for (const key of Object.keys(this.filters)) {

                    let value = this.filters[key]

                    if (value === null || value === undefined || value === '') {
                        continue
                    }

                    const item = this.fields.find(ele => ele.key === key)


                    if (item.filterType === 'date') {
                        const newEndDate = this.$dayjs(value.end).hour(23).minute(59).second(59).toISOString()
                        const newStartDate = this.$dayjs(value.start).hour(0).minute(0).second(0).toISOString()

                        filter += `&${  key  }.gte=${ newStartDate  }&`
                        filter += `&${  key  }.lte=${ newEndDate  }&`

                        continue
                    }

                    const filterFormatter = this.getFilterFormatter(key)

                    if (filterFormatter) {
                        value = filterFormatter(this.filters[key])
                    }


                    if (item && item.filterKey) {
                        filter += `&${  item.filterKey  }=${ value  }&`
                    } else {
                        filter += `&${  key  }=${ value  }&`
                    }
                }

                if (this.additionalFilters) {
                    for (const key of Object.keys(this.additionalFilters)) {
                        const item = this.additionalFilters[key]

                        if (item === null || item === undefined || item === '') {
                            continue
                        }

                        if (item.type === 'date') {
                            filter += `&${  key  }.gte=${ item.value.start  }&`
                            filter += `&${  key  }.lte=${ item.value.end  }&`
                        }
                    }
                }
                return filter
            },
            async loadData() {
                if (this.apiUrl === '') {
                    return
                }

                if (this.loadingActive) {
                    return
                }

                try {
                    this.loadingActive = true

                    this.showLoader = true

                    const filters = this.getFilters()

                    const response = await this.$http.get(`${this.apiUrl  }?page=${  this.pagination.current_page  }&perPage=${  this.pagination.per_page}${  filters}`)
                    this.apiItems = response.data.items ?? []
                    // this.showItems = this.apiItems
                    this.pagination = response.data.pagination
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.loadingActive = false
                    this.showLoader = false
                }
            },
            getTableName() {
                if (this.name) return `t.${this.name}`
                return 't'
            },
            setTableFiltersToURL() {
                const payloadData = {
                    filters: this.filters,
                    per_page: this.pagination.per_page,
                    current_page: this.pagination.current_page
                }

                const payload = Buffer.from(JSON.stringify(payloadData)).toString('base64')
                let payloadOld = ''
                if (this.$route.query && this.$route.query[this.getTableName()]) {
                    payloadOld = this.$route.query[this.getTableName()]
                }

                const type = (typeof (payloadOld))
                if (type === 'string' && payload !== payloadOld) {
                    this.$router.replace({ path: this.$route.path, query: { ...this.$route.query, [this.getTableName()]: payload } })
                } else if (type === 'object' && payload !== payloadOld[0]) {
                    this.$router.replace({ path: this.$route.path, query: { ...this.$route.query, [this.getTableName()]: payload } })
                }
            },
            readTableFiltersFromURL() {
                if (!this.$route.query || !this.$route.query[this.getTableName()]) return
                // get b64 date from query parameter and parse it
                const payload = this.$route.query[this.getTableName()]
                const data = JSON.parse(atob(Buffer.from(payload).toString()))
                this.filters = data.filters
                this.pagination.per_page = data.per_page
                this.pagination.current_page = data.current_page
            },
            getIndexes(event) {
                let colIndex = -1
                let rowIndex = -1

                const parent = event.target.closest('td')

                if (parent) {
                    const tmp = parent.getAttribute('aria-colindex')

                    if (tmp) {
                        colIndex = parseInt(tmp) - 1
                    }
                }

                if (parent && parent.childNodes.length > 0) {
                    const item = parent.childNodes[0]
                    if (item) {
                        const tmp = item.getAttribute('aria-rowindex')

                        if (tmp) {
                            rowIndex = parseInt(tmp)
                        }
                    }
                }

                return {rowIndex, colIndex}
            },
            handleClick(event) {
                const newTime = new Date()
                const {rowIndex, colIndex} = this.getIndexes(event)

                if ((this.computedFields[colIndex] && this.computedFields[colIndex].disableSelect && this.computedFields[colIndex].disableSelect === true) ||
                    (this.computedFields[colIndex] && this.computedFields[colIndex].editable && this.computedFields[colIndex].editable === false)) {
                    return
                }

                if (this.apiUrl === '' && rowIndex >= 0) { // checkboxes in selectanje možno le brez api klica
                    let itemId = ''
                    let items = []

                    if (!this.isFiltersEmpty()) {
                        items = this.manualFiltering()
                    } else {
                        items = this.showItems
                    }

                    itemId = items[(this.pagination.per_page * (this.pagination.current_page - 1)) + rowIndex][this.selectionId]

                    const rowId = this.selectedRows.indexOf(itemId)
                    if (rowId === -1) {
                        this.selectedRows.push(itemId)
                    } else {
                        this.selectedRows.splice(rowId, 1)
                    }

                    this.indeterminateAndSelectAllSet()
                }

                if (newTime - this.lastClickOnTable <= 500) {
                    if (rowIndex >= 0 && colIndex >= 0) {
                        const colKey = this.fields[colIndex].key

                        this.showEditing = `${rowIndex  }-${  colKey}`
                        this.selectedCol = colIndex
                        this.selectedRow = rowIndex

                        if (this.fields[colIndex].filterType === 'input' || !this.fields[colIndex].filterType) {
                            this.$nextTick(() => {
                                if (this.$refs[`input_field_editing_${  this.showEditing}`]) {
                                    this.$refs[`input_field_editing_${  this.showEditing}`].focus()
                                }
                            })
                        }


                    }
                }

                if (this.showEditing) {

                    if (!(rowIndex >= 0 && colIndex >= 0)) {
                        this.showEditing = ''
                        this.selectedCol = -1
                        this.selectedRow = -1
                        return
                    }

                    const colKey = this.fields[colIndex].key
                    const tmp   = `${rowIndex  }-${  colKey}`

                    if (tmp !== this.showEditing) {
                        this.showEditing = ''
                        this.selectedCol = -1
                        this.selectedRow = -1
                        return
                    }
                }

                this.lastClickOnTable = newTime
            },
            handleClickWindow(evnt) {

                const searchString = `#${  this.getTableName() }`
                const item = evnt.target.closest(searchString)
                const newTime = new Date()


                if (!item && newTime - this.lastClickCheck >= 700) {
                    this.showEditing = ''
                    return
                }

                this.lastClickCheck = newTime
            },
            getSelected() {
                return this.selectedRows
            },
            handleKeyboard(e) {
                if (e.key === 'Enter') {
                    if (this.showEditing) {
                        if (this.$refs[`input_field_editing_${  this.showEditing}`]) {
                            this.$refs[`input_field_editing_${  this.showEditing}`].blur()
                        }
                    }
                }
            }
        },
        async mounted() {
            await this.readTableFiltersFromURL()

            if (this.apiUrl === '') {
                this.selectedRows = this.selected ?? []
                this.pagination.total_items = this.showItems.length
                this.pagination.per_page = 10
                this.pagination.current_page = 1
                this.showLoader = false
            } else {
                await this.loadData()
            }

            if (this.$refs[`customTable_${  this.getTableName()}`]) {
                const customTable = this.$refs[`customTable_${  this.getTableName()}`].$el
                customTable.addEventListener('click', this.handleClick)
            }

            window.addEventListener('click', this.handleClickWindow)
            window.addEventListener('keypress', this.handleKeyboard)
            this.dateInput = false
            this.indeterminateAndSelectAllSet()
        },
        beforeDestroy() {
            window.removeEventListener('click', this.handleClickWindow)
            window.removeEventListener('keypress', this.handleKeyboard)

            if (this.$refs[`customTable_${  this.getTableName()}`]) {
                const customTable = this.$refs[`customTable_${  this.getTableName()}`].$el
                customTable.removeEventListener('click', this.handleClick)
            }
        },
        watch:{
            items() {
                this.pagination.total_items = this.items.length
            }
        }
    }

</script>

<style>
  table.table tr.table-light td {
    background-color: #b4bfb5 !important;
  }

  table.table tr.table-dark td {
    background-color: #343434 !important;
  }

  .customTable .b-table tr:last-child td:last-child{
    border-bottom-right-radius: 10px !important;
  }

  .customTable .b-table tr:last-child td:first-child{
    border-bottom-left-radius: 10px !important;
  }

  .customTable .b-table tr:first-child th:last-child{
    border-top-right-radius: 10px !important;
  }

  .customTable .b-table tr:first-child th:first-child{
    border-top-left-radius: 10px !important;
  }

  .customTable .b-table td{
    padding: 10px 5px !important;
  }

  .customInputFieldTable:focus{
    outline: none !important;
    border-color: transparent;
  }

  .customTable.b-table-sticky-header{
    border-radius: 10px !important;
  }

  .customTable .vs__dropdown-toggle{
    height: 30px !important;
  }

  .customTable .vs__search{
    width: 100% !important;
  }

  .pageSelect .vs__dropdown-toggle{
    height: 30px !important;
  }

  .customTable .vs__search{
    position: absolute !important;
  }
</style>

<style scoped>
.invert {
  filter: invert(1);
}

.customDatePicker{
  height: 30px !important;
}

  .floating-filter{
    width: 100%;
    height: 30px !important;
  }

  .customTable .textCutSelect{
    width: calc(100%) !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .customTable .textCut{
    width: calc(100% - 30px) !important;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
.large-icon {
  width: 18px;  /* Adjust width as needed */
  height: 18px; /* Adjust height as needed */
}

</style>